<footer class="py-5 container-fluid mt-5">
    <div class="row">
        <div class="col">
            <p class="text-center text-white p-0 m-0"><i class="fas fa-map-marker-alt crojo"></i> Oficina: Plaza Olazábal 137 - La Plata</p>
            <p class="text-center text-white small p-0 mb-1">Coordinar cita previa <a class="enlace-text" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20coordinar%20una%20cita%20en%20la%20oficina.">Presione aquí</a></p>
            <p class="text-center text-white small p-0"><i class="fas fa-plane-departure"></i> Envíos a todo el país</p>
            <div class="text-center mb-4">
                <a href="https://www.google.com.ar/maps/place/Plaza+Olaz%C3%A1bal+137,+B1900+La+Plata,+Provincia+de+Buenos+Aires/@-34.9062225,-57.9616776,21z/data=!4m5!3m4!1s0x95a2e7b335476315:0xac41e8d9657ad0bd!8m2!3d-34.9062148!4d-57.9615777" target="_blank" class="red ml-2 mr-2"><i class="fas fa-map-marker-alt    "></i></a>
                <a href="https://www.instagram.com/likeiphone.arg/" target="_blank" class="red ml-2 mr-2"><i class="fab fa-instagram"></i></a>
                <a href="https://www.facebook.com/Like.iphone.arg" target="_blank" class="red ml-2 mr-2"><i class="fab fa-facebook-f"></i></a>
                <a target="_blank" href="https://www.tiktok.com/@likeiphone.ok" class="red ml-2 mr-2"><i class="fab fa-tiktok"></i></a>
            </div>
            <a href="#" routerLink="/">
                <img height="40" class="d-block mx-auto" src="../../assets/img/logoVerano.png" alt="">
            </a>
        </div>
    </div>
</footer>